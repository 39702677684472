import { Pipe, PipeTransform } from '@angular/core';

import { formatDateTime } from '../utils/date-time';
import { ParseDateTimeInput } from '../utils/parse-date-time';

@Pipe({ name: 'formatDateTime', standalone: true })
export class FormatDateTimePipe implements PipeTransform {
  transform(value: ParseDateTimeInput): string {
    return formatDateTime(value);
  }
}
